import React, { useEffect, useState, useContext } from "react";
import { SkeletonPage, Layout, Spinner } from "@shopify/polaris";
import { useNavigate, useSearchParams } from "react-router-dom";
import useLocalStorage from "../hooks/useLocalStorage";
import useAuth from "../hooks/useAuth";
import { PageContext } from "../context/PageContext";

export default function Login() {
  const { token, signIn } = useAuth();

  const page = useContext(PageContext);
  const [error, setError] = useState(false);
  const [urlSearchParams] = useSearchParams();
  const [currentLocation] = useLocalStorage(
    "current_location",
    urlSearchParams.get("callback") || "/installations"
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (error) return;

    if (token && currentLocation) {
      return navigate(currentLocation);
    }

    signIn().catch(() => {
      console.log(error);
      setError(true);
      page.setLoading(false);
    });
  }, [token, currentLocation]);

  // TODO: voltar aqui quando o layout estiver pronto
  return (
    <SkeletonPage>
      <Layout>{error ? <p>Error...</p> : <Spinner />}</Layout>
    </SkeletonPage>
  );
}
