import React, { useState, useRef, useCallback } from "react";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import { AuthenticationProvider } from "./context/AutheticationContext";
import { APIProvider } from "./context/APIContext";
import { PageProvider } from "./context/PageContext";
import { InstallationProvider } from "./context/InstallationContext";
import { GithubProvider } from "./context/GithubContext";

import PrivateRoutes from "./components/PrivateRoutes";
import Frame from "./components/Frame";
import OnInstallationRoutes from "./components/OnInstallationRoutes";

import Login from "./pages/Login";
import Page404 from "./pages/404";
import Installations from "./pages/Installations";
import Projects from "./pages/Projects";
import Project from "./pages/Project";
import Assets from "./pages/Assets";

function App() {
  return (
    <AuthenticationProvider>
      <GithubProvider>
        {/* <APIProvider> */}
        <PageProvider>
          {/* <InstallationProvider> */}
          <Router>
            <Routes>
              <Route element={<PrivateRoutes />}>
                <Route
                  path="/installations"
                  element={
                    <Frame>
                      <Installations />
                    </Frame>
                  }
                />

                <Route
                  path="/installations/:installationId"
                  element={<OnInstallationRoutes />}
                >
                  <Route path="" element={<Navigate to="projects" replace />} />

                  <Route path="projects" element={<Projects />} />
                  <Route path="projects/:projectId" element={<Project />} />

                  <Route path="assets" element={<Assets />} />
                </Route>
              </Route>
              <Route path="/login" element={<Login />} />
              <Route path="/" element={<Navigate to="/login" replace />} />
              <Route path="*" element={<Page404 />} />
            </Routes>
          </Router>
          {/* </InstallationProvider> */}
        </PageProvider>
        {/* </APIProvider> */}
      </GithubProvider>
    </AuthenticationProvider>
  );
}

export default App;
