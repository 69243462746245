import { Octokit } from "octokit";
import React, { useEffect, useMemo, useState } from "react";
import useAuth from "../hooks/useAuth";

const GithubContext = React.createContext({
  octokit: null,
});

function GithubProvider({ children }) {
  const { user } = useAuth();

  const octokit = useMemo(() => {
    if (!user || !user.githubToken) return;
    return new Octokit({ auth: user.githubToken });
  }, [user, user && user.githubToken]);

  return (
    <GithubContext.Provider
      value={{
        octokit,
      }}
    >
      {children}
    </GithubContext.Provider>
  );
}

export { GithubContext, GithubProvider };
