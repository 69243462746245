import React from "react";
import useLocalStorage from "../hooks/useLocalStorage";
import { signIn, signOut } from "../lib/authentication";

const AuthenticationContext = React.createContext({
  token: null,
  user: null,
  signOut: () => {},
  signIn: () => {},
});

function AuthenticationProvider({ children }) {
  const [token, setToken] = useLocalStorage("@token", null);
  const [user, setUser] = useLocalStorage("@user", null);

  const _signIn = () => {
    return signIn().then(({ user, token: newToken }) => {
      debugger;
      setUser(user);
      setToken(newToken);
    });
  };

  const _signOut = () => {
    return signOut().then(() => {
      setUser(null);
      setToken(null);
    });
  };

  return (
    <AuthenticationContext.Provider
      value={{
        token,
        user,
        signIn: _signIn,
        signOut: _signOut,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
}

export { AuthenticationContext, AuthenticationProvider };
