import React from "react";

import { Navigate, Outlet, useLocation } from "react-router-dom";

import useAuth from "../hooks/useAuth";
// import { getToken, onAuthChange, signOut } from "../lib/authentication";

const PrivateRoutes = () => {
  const auth = useAuth();
  const location = useLocation();

  // onAuthChange((user) => {
  //   if (user) {
  //     getToken(user)
  //       .then((idToken) => {
  //         debugger;
  //       })
  //       .catch((e) => {
  //         debugger;
  //       });
  //   } else {
  //     signOut();
  //   }
  // });

  if (auth.token) {
    return <Outlet />;
  } else {
    return <Navigate to={`/login?callback=${location.pathname}`} replace />;
  }
};

export default PrivateRoutes;
