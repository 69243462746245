import { initializeApp } from "firebase/app";
import {
  getAuth,
  signOut as rawSignOut,
  signInWithPopup,
  GithubAuthProvider,
  getIdToken,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAUF40O4ELiZeLO0WU2b9ihcj8UQ5MlNmo",
  authDomain: "warehouse-prod-56727.firebaseapp.com",
  projectId: "warehouse-prod-56727",
  storageBucket: "warehouse-prod-56727.appspot.com",
  messagingSenderId: "758431084376",
  appId: "1:758431084376:web:7440c0b4e185816ecfff2d",
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export function onAuthChange(fn) {
  return auth.onAuthStateChanged(fn);
}

export function signOut() {
  return rawSignOut(auth)
    .then(() => {
      debugger;
      return true;
    })
    .catch((error) => {
      debugger;
      return false;
      // An error happened.
    });
}

export function getToken(user) {
  return getIdToken(user).catch((e) => {
    debugger;
  });
}

export function signIn() {
  const provider = new GithubAuthProvider();
  provider.setCustomParameters({
    allow_signup: "false",
  });

  return signInWithPopup(auth, provider).then((result) => {
    // const credential = GithubAuthProvider.credentialFromResult(result);
    // const githubToken = credential.accessToken;

    return {
      user: {
        name: result.user.displayName,
        photo: {
          url: result.user.photoURL,
        },
        // githubToken,
      },
      token: result.user.accessToken,
    };
  });
}
