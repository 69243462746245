import React, { useContext, useEffect } from "react";

import {
  Card,
  Heading,
  Avatar,
  Layout,
  Page,
  ResourceItem,
  Button,
  ResourceList,
  SkeletonBodyText,
  SkeletonPage,
  InlineError,
  TextContainer,
  TextStyle,
} from "@shopify/polaris";

import { PageContext } from "../context/PageContext";
import useGithub from "../hooks/useGithub";

const Installations = () => {
  const page = useContext(PageContext);
  const github = useGithub();

  const data = null;
  const error = null;

  useEffect(async () => {
    page.setLoading(true);
    // octokit.rest.apps.getAuthenticated();
    if (!github) {
      console.log("no github");
      return;
    }

    github.request("GET /user/installations", {
      headers: {},
    });

    page.setLoading(false);
  }, []);

  // useEffect(() => {
  //   page.setLoading(loading);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [loading]);

  const resourceName = {
    singular: "installation",
    plural: "installations",
  };

  const installations = (data && data.installations) || [];

  return page.loading ? (
    <SkeletonPage title="Installations">
      <Layout>
        <Layout.Section oneThird>
          <SkeletonBodyText />
        </Layout.Section>

        <Layout.Section>
          <Card sectioned>
            <SkeletonBodyText />
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  ) : (
    <>
      <Page
        title="Installations"
        primaryAction={
          <Button
            primary
            onClick={() =>
              (window.location =
                "https://github.com/apps/dependencies-warehouse/installations/new")
            }
          >
            New installation
          </Button>
        }
      >
        {error && (
          <InlineError message="Error to fetch installations data, try again later" />
        )}

        {!error && (
          <Layout>
            <Layout.Section oneThird>
              <div style={{ marginTop: "var(--p-space-5)" }}>
                <TextContainer>
                  <Heading id="selectInstallations">
                    Select your installation
                  </Heading>
                  <p>
                    <TextStyle variation="subdued">
                      To analyse you projects we need your github permissions,
                      here is the installations we have in your name.
                    </TextStyle>
                  </p>
                </TextContainer>
              </div>
            </Layout.Section>
            <Layout.Section>
              <Card>
                <ResourceList
                  resourceName={resourceName}
                  items={installations}
                  renderItem={(item) => {
                    const { id, name, avatarUrl } = item;
                    const media = (
                      <Avatar customer size="medium" source={avatarUrl} />
                    );

                    return (
                      <ResourceItem
                        id={id}
                        url={`/installations/${id}`}
                        verticalAlignment="center"
                        media={media}
                        accessibilityLabel={`View details for ${name}`}
                      >
                        <h3>
                          <TextStyle variation="strong">{name}</TextStyle>
                        </h3>
                      </ResourceItem>
                    );
                  }}
                ></ResourceList>
              </Card>
            </Layout.Section>
          </Layout>
        )}
      </Page>
    </>
  );
};

export default Installations;
